<template>
  <v-main>
    <v-container fill-height>
      <v-row align="center">
        <v-col cols="12" justify-self="center">
          <error-page :description="$i18n.t('errors.page.403.title')" error="403"></error-page>
        </v-col>
      </v-row>
    </v-container>
  </v-main> 
</template>

<script>
import ErrorPage from "./../../components/utils/error";

export default {
  data: () => ({}),
  components: {
    ErrorPage
  }
};
</script>